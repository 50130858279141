/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { resolveTimeZone as e } from "../intl/date.js";
import { system as t } from "../time/timeZoneUtils.js";
import { IANAZone as n, DateTime as s } from "luxon";
const r = {
  milliseconds: 1,
  seconds: 1e3,
  minutes: 6e4,
  hours: 36e5,
  days: 864e5,
  weeks: 6048e5,
  months: 26784e5,
  years: 31536e6,
  decades: 31536e7,
  centuries: 31536e8
};
function o(r, o, i, c = t) {
  const u = new n(e(c)),
    a = s.fromJSDate(r, {
      zone: u
    }),
    l = "decades" === i || "centuries" === i ? "year" : T(i);
  return "decades" === i && (o *= 10), "centuries" === i && (o *= 100), a.plus({
    [l]: o
  }).toJSDate();
}
function i(e, t, n = "milliseconds") {
  const s = e.getTime(),
    r = m(t, n, "milliseconds");
  return new Date(s + r);
}
function c(r, o, i = t) {
  const c = new n(e(i)),
    u = s.fromJSDate(r, {
      zone: c
    });
  if ("decades" === o || "centuries" === o) {
    const e = u.startOf("year"),
      {
        year: t
      } = e,
      n = t - t % ("decades" === o ? 10 : 100);
    return e.set({
      year: n
    }).toJSDate();
  }
  const a = T(o);
  return u.startOf(a).toJSDate();
}
function u(e, t, n = "milliseconds") {
  const s = new Date(m(t, n, "milliseconds"));
  return s.setUTCFullYear(e.getUTCFullYear(), e.getUTCMonth(), e.getUTCDate()), s;
}
function a(e, t = "milliseconds") {
  const n = m(e.getUTCHours(), "hours", "milliseconds"),
    s = m(e.getUTCMinutes(), "minutes", "milliseconds"),
    r = m(e.getUTCSeconds(), "seconds", "milliseconds");
  return m(n + s + r + e.getUTCMilliseconds(), "milliseconds", t);
}
function l(e, t) {
  const n = new Date(e.getTime());
  return n.setUTCFullYear(t.getFullYear(), t.getMonth(), t.getDate()), n;
}
function d(e) {
  const t = new Date(0);
  return t.setHours(0), t.setMinutes(0), t.setSeconds(0), t.setMilliseconds(0), t.setFullYear(e.getUTCFullYear(), e.getUTCMonth(), e.getUTCDate()), t;
}
function m(e, t, n) {
  if (0 === e) return 0;
  return e * r[t] / r[n];
}
function f(e) {
  return e instanceof Date && !isNaN(e.getTime());
}
function g(e, t) {
  return e && t ? e.intersection(t) : e || t;
}
function T(e) {
  switch (e) {
    case "milliseconds":
      return "millisecond";
    case "seconds":
      return "second";
    case "minutes":
      return "minute";
    case "hours":
      return "hour";
    case "days":
      return "day";
    case "weeks":
      return "week";
    case "months":
      return "month";
    case "years":
      return "year";
  }
}
export { m as convertTime, f as isValidDate, u as makeUTCTime, g as mergeTimeExtents, r as millisecondsPerTimeUnit, o as offsetDate, i as offsetDateUTC, l as resetUTCDate, a as timeSinceUTCMidnight, c as truncateDate, d as truncateLocalTime };